import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import ResponsiveDatePickers from './components/WorkReport/1_ResponsiveDatePickers';
import { Box, Button, TextField, Backdrop, CircularProgress } from '@mui/material';
import FileUpload from './components/WorkReport/6_FileUpload';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import { axiosPrivate as axios } from '../Api/axios';
import { useAuth } from '@clerk/clerk-react';

const getTodayDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

const getTodayDayOfWeek = () => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  return daysOfWeek[today.getDay()];
};

function MilesReport() {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    selectedDate: getTodayDate(),
    dayOfWeek: getTodayDayOfWeek(),
    miles: '',
    comment: '',
    file: []
  });

  const [loading, setLoading] = useState(false);

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedDate: newDate
    }));
  };

  const handleDayChange = (newDayOfWeek) => {
    setFormData((prevData) => ({
      ...prevData,
      dayOfWeek: newDayOfWeek
    }));
  };

  const handleMilesChange = (newMiles) => {
    setFormData((prevData) => ({
      ...prevData,
      miles: newMiles
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (file) => {
    setFormData({
      ...formData,
      file: file
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (key === 'file') {
        Array.from(formData[key]).forEach((file) => {
          formDataToSend.append('file', file);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to submit the report?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      });

      if (result.isConfirmed) {
        setLoading(true);

        await axios.post('/milesreport', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${await getToken()}`,
          },
        });

        setLoading(false);

        await Swal.fire(
          'Submitted!',
          'Your report has been submitted successfully.',
          'success'
        );

        navigate(0); 
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting the form:', error);

      await Swal.fire(
        'Error!',
        error.response.data.message,
        'error'
      );
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={0} sx={{ padding: 1, marginTop: 8 }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 9,
              width: '100%'
            }}
          >
            <ResponsiveDatePickers
              onDateChange={handleDateChange}
              onMilesChange={handleMilesChange}
              onDayChange={handleDayChange}
              use="milesreport"
            />
            <TextField
              id="comment"
              name="comment"
              label="Comment"
              variant="outlined"
              value={formData.comment}
              onChange={handleInputChange}
              sx={{ maxWidth: '100%' }}
            />
            <FileUpload onFileSelect={handleFileChange} />
            <Button
              size="large"
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
            >
              Report
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
}

export default MilesReport;
