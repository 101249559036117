import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './App/1_Header/1_Header';
import Footer from './App/3_Footer/3_Footer';
import { Outlet, useNavigate } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";

const sections = [
  { title: 'Work Report', url: '/workreport' },
  { title: 'Part Request', url: '/partrequest' },
  { title: 'Weekly Report', url: '/weeklyreport' },
  { title: 'Miles Report', url: '/milesreport' },
  { title: 'Serial Number Check', url: '/sncheck' },
  { title: 'Settings', url: '/settings' },
  { title: 'Logout' }
];

const defaultTheme = createTheme({
  spacing: 1,
});

export default function App() {
  const { signOut } = useClerk();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut()
      .then(() => {
        navigate("/login"); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
  };

  const handleSectionClick = (section) => {
    if (section.title === 'Logout') {
      handleLogout();
    } else {
      navigate(section.url);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header
          title="<-.->"
          sections={sections}
          onSectionClick={handleSectionClick}
        />
        <main>
          <Outlet />
        </main>
      </Container>
      <Footer
        title=""
        description=""
      />
    </ThemeProvider>
  );
}
