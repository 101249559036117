import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import ResponsiveDatePickers from './components/WorkReport/1_ResponsiveDatePickers';
import InputAdornments from './components/WorkReport/2_InputAdornment';
import { Box, Button, TextField, Backdrop, CircularProgress } from '@mui/material';
import OvertimeForm from './components/WorkReport/4_OvertimeForm';
import FileUpload from './components/WorkReport/6_FileUpload';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import { axiosPrivate as axios } from '../Api/axios';
import { useAuth } from '@clerk/clerk-react';

const getTodayDate = () => {
  const options = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  return new Date().toLocaleDateString('en-CA', options);
};

const getTodayDayOfWeek = () => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  return daysOfWeek[today.getDay()];
};

function WorkReport() {
  const { getToken } = useAuth();
  const navigate = useNavigate();

  // State to manage form data
  const [formData, setFormData] = useState({
    selectedDate: getTodayDate(),
    dayOfWeek: getTodayDayOfWeek(),
    miles: '',
    wo: '',
    rep: '',
    overtime: '',
    hours: '',
    diagnosis: '',
    evidence: '',
    action: '',
    parts: '',
    file: []
  });

  // State to manage loading
  const [loading, setLoading] = useState(false);

  const handleDateChange = (newDate) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedDate: newDate
    }));
  };

  const handleDayChange = (newDayOfWeek) => {
    setFormData((prevData) => ({
      ...prevData,
      dayOfWeek: newDayOfWeek
    }));
  };

  const handleMilesChange = (newMiles) => {
    setFormData((prevData) => ({
      ...prevData,
      miles: newMiles
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (file) => {
    setFormData({
      ...formData,
      file: file
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a FormData object
    const formDataToSend = new FormData();

    for (const key in formData) {
      if (key === 'file') {
        Array.from(formData[key]).forEach((file) => {
          formDataToSend.append('file', file);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    }

    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to submit the work report?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      });

      if (result.isConfirmed) {
        setLoading(true); // Show loading indicator

        // Make the POST request using await
        await axios.post('/workreport', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${await getToken()}`,
          },
        });

        setLoading(false); // Hide loading indicator

        await Swal.fire(
          'Submitted!',
          'Your work report has been submitted successfully.',
          'success'
        );

        navigate(0); // Refresh or redirect as needed
      }
    } catch (error) {
      setLoading(false); // Hide loading indicator
      console.error('Error submitting the form:', error);

      await Swal.fire(
        'Error!',
        error.response.data.message,
        'error'
      );
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper elevation={0} sx={{ padding: 1, marginTop: 8 }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 9,
              width: '100%'
            }}
          >
            <ResponsiveDatePickers
              onDateChange={handleDateChange}
              onMilesChange={handleMilesChange}
              onDayChange={handleDayChange}
              use="workreport"
            />
            <InputAdornments
              woValue={formData.wo}
              repValue={formData.rep}
              onWoChange={(e) => setFormData({ ...formData, wo: e.target.value })}
              onRepChange={(e) => setFormData({ ...formData, rep: e.target.value })}
            />
            <OvertimeForm
              overtimeValue={formData.overtime}
              hoursValue={formData.hours}
              onOvertimeChange={(value) => setFormData({ ...formData, overtime: value })}
              onHoursChange={(value) => setFormData({ ...formData, hours: value })}
            />

            <TextField
              id="diagnosis"
              name="diagnosis"
              label="Diagnosis"
              variant="outlined"
              value={formData.diagnosis}
              onChange={handleInputChange}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              id="evidence"
              name="evidence"
              label="Evidence"
              variant="outlined"
              value={formData.evidence}
              onChange={handleInputChange}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              id="action"
              name="action"
              label="Action"
              variant="outlined"
              value={formData.action}
              onChange={handleInputChange}
              sx={{ maxWidth: '100%' }}
            />
            <TextField
              id="parts"
              name="parts"
              label="Parts"
              variant="outlined"
              value={formData.parts}
              onChange={handleInputChange}
              sx={{ maxWidth: '100%' }}
            />
            <FileUpload onFileSelect={handleFileChange} />
            <Button
              size="large"
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
            >
              Report
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
}

export default WorkReport;
